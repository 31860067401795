<template>
    <div v-if="record && rsettings">
        <div v-if="rsettings[0].settings.shremarks">
            <span>CLASS TEACHER REMARKS: &nbsp;
                <strong v-if="record.remarks && record.remarks.form_teach_remark" class="bborder">{{record.remarks.form_teach_remark}}</strong>
                <strong v-else>______________________________________________</strong>
            </span> <br>
            <span>SCHOOL HEAD REMARKS:  &nbsp;
                <strong v-if="record.remarks && record.remarks.school_head_remark" class="bborder">{{record.remarks.school_head_remark}}</strong>
                <strong v-else>_______________________________________________</strong>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Remarks',
    props:[
        'rsettings',
        'record',
    ],
}
</script>