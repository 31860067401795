<template>
    <v-row>
        <v-col cols="12">
            <h5 :class="styler">SIGNATURE / STAMP</h5>
            <div v-if="school && school.stamp">
                <img :src="school.stamp" height="50px" width="100px">
            </div>
            <div v-else style="background:#f5f5f5">&nbsp;
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'SignatureAndStamp',
    props:[
        'school',
        'styler'
    ],
}
</script>