<template>
    <v-row>
        <v-col cols="12" class="d-flex d-sm-none d-print-none">
        <div>
            Click the Print button to see a better preview
        </div>
        </v-col>
        <v-col cols="12"> 
            <div class="hborder print-zone" v-for="(record, i) in records" :key="i">
                <v-row class="mt-1 px-3">
                    <v-col cols="12" class="text-center">
                        <school-logo :school="school"/>
                        <school-name :school="school"/>
                    </v-col>
                    <v-col cols="12">
                        <h3 class="blue--text">{{record.subject}}</h3>
                        <small>{{record.class.name}} / {{record.term.name}}Term - {{record.term.session}} Session</small>
                        <h3>Broadsheet by Subjects</h3>
                    </v-col>
                </v-row>
                <v-row class="px-4 py-4">
                    <v-col cols="12" style="background:#f5f5f5">
                    <v-simple-table dense>
                            <template v-slot:default>
                            <thead>
                                <tr>   
                                <th class="text-left">Subject Pos.#</th>
                                <th class="text-left">FullName #</th>
                                <th class="text-left">Admission #</th>
                                <th class="text-left">CA1 </th>
                                <th class="text-left" v-if="school.kinds_of_ca > 1">CA2 </th>
                                <th class="text-left" v-if="school.kinds_of_ca > 2">CA3 </th>
                                <th class="text-left" v-if="school.kinds_of_ca > 3">CA4 </th>
                                <th class="text-left">Exam </th>
                                <th class="text-left">Total</th>
                                <th class="text-left">Grade</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="score in record.scores"
                                :key="score.result_id"
                                >
                                <td>
                                    <v-chip color="#FFF9C4" v-if=" score.this_term_scores.computed_subject_position == '1st'">1st</v-chip>
                                    <v-chip color="#F5F5F5" v-else-if=" score.this_term_scores.computed_subject_position == '2nd'">2nd</v-chip>
                                    <v-chip color="#D7CCC8" v-else-if=" score.this_term_scores.computed_subject_position == '3rd'">3rd</v-chip>
                                    <v-chip color="white" v-else>{{score.this_term_scores.computed_subject_position}}</v-chip>
                                </td>
                                <td>{{ score.student.fullname }}</td>
                                <td>{{ score.student.student_regno }}</td>
                                <td>{{ score.this_term_scores.ca1 }}</td>
                                <td v-if="school.kinds_of_ca > 1">{{ score.this_term_scores.ca2 }}</td>
                                <td v-if="school.kinds_of_ca > 2">{{ score.this_term_scores.ca3 }}</td>
                                <td v-if="school.kinds_of_ca > 3">{{ score.this_term_scores.ca4 }}</td>
                                <td>{{ score.this_term_scores.exam }}</td>
                                <td ><v-chip color="#FFFF8D">{{ score.this_term_scores.total }}</v-chip></td>
                                <td><v-chip text color="#B2EBF2">{{ score.this_term_scores.grade }}</v-chip></td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <small v-if="school.plan == 'Free'">Printed from MYSCH.NG</small>
                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import SchoolLogo from './Parts/SchoolLogo.vue'
import SchoolName from './Parts/SchoolName.vue'
export default {
  components: { SchoolLogo, SchoolName },
    name: 'BroadSheetBySubjects',
    props:[
        'records', 
        'school',
        'settings'
    ],
    data () {
        return {
           //
        }
    },
    methods:{
    }
}
</script>

<style scoped>
@media print{
    .print-zone{
        page-break-after: always;
        position: relative !important;
        font-weight: 500;
    }

}
@media only screen and (max-width: 450px) {
    .print-zone{
        zoom: 0.5;
        -moz-transform: scale(0.5);
    }
}
@media only screen and (min-width: 451px) and (max-width: 850px) {
    .print-zone{
        zoom: 0.7;
        -moz-transform: scale(0.7);
    }
}
</style>
