<template>
    <div v-if="record">
        <span v-if="rsettings[0].settings.csize">Students in Class: <strong>{{record.total_students_in_class}}</strong></span>
    </div>
</template>

<script>
export default {
    name: 'ClassSize',
    props:[
        'record',
        'rsettings',
    ],
}
</script>