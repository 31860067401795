<template>
    <v-row>
        <v-col cols="12">
            <v-form>
                <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3">
                    <v-select
                    :items="terms"
                    item-text="term_session"
                    item-value="parameter_id"
                    @change="fetchStudents()" 
                    label="Select Term:"
                    v-model="form.parameter_id"
                    outlined
                    dense
                    :rules="[v => !!v || 'Term need to be selected!']"
                    required
                    ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" lg="3">
                    <v-select
                    :items="classes"
                    item-text="class"
                    item-value="class_id"
                    label="Select Class:"
                    v-model="form.clasx_id"
                    outlined
                    dense
                    :rules="[v => !!v || 'Class need to be selected!']"
                    required
                    @change="fetchStudents()"
                    ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" v-if="!accessToken">
                    <v-select
                    :items="students"
                    item-text="name"
                    item-value="scs_id"
                    @change="emitForm" label="Select Student (optional):"
                    v-model="form.scs_id" 
                    dense
                    outlined
                    clearable
                    placeholder="Leave blank to select all students in class"
                    ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" v-if="!accessToken">
                    <v-select
                    :disabled="school.plan =='Free'"
                    :items="school.plan =='Free' ? templates[0] : templates.filter((template) => {
                        return template.schid === school.school_id
                    })"
                    item-text="name"
                    item-value="id"
                    @change="emitForm" label="Use Custom Template:"
                    v-model="rsettings.template"
                    outlined
                    dense
                    ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3"
                    v-if="school.school_id == '5e1e7978-e58e-4f95-8269-3069cbf5f402'"
                    >
                    <v-select
                    :items="['2','3','4']"
                    label="How many Continuous Assessment"
                    v-model="cacount"
                    @change="updateCaCount()"
                    outlined dense
                    required
                    ></v-select>
                    </v-col>
                    
                    <v-col cols="12" v-if="!accessToken">
                        <v-btn x-small text color="info" @click="showMore = !showMore">Show/Hide More Settings</v-btn>                        
                        <v-divider></v-divider>
                    </v-col>
                    
                    <v-col cols="12" v-if="showMore">
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="3">
                            <v-select
                            :items="scoreGroupMax"
                            @change="emitForm" label="1st CA Max Score:"
                            v-model="form.ca1_maxscore" 
                            dense
                            outlined
                            ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" v-if="school.kinds_of_ca > 1">
                            <v-select
                            :items="scoreGroupMax"
                            @change="emitForm" label="2nd CA Max Score:"
                            v-model="form.ca2_maxscore" 
                            dense
                            outlined
                            ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" v-if="school.kinds_of_ca > 2">
                            <v-select
                            :items="scoreGroupMax"
                            @change="emitForm" label="3rd CA Max Score:"
                            v-model="form.ca3_maxscore" 
                            dense
                            outlined
                            ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" v-if="school.kinds_of_ca > 3">
                            <v-select
                            :items="scoreGroupMax"
                            @change="emitForm" label="4th CA Max Score:"
                            v-model="form.ca4_maxscore" 
                            dense
                            outlined
                            ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                            <v-select
                            :items="scoreGroupMax"
                            @change="emitForm" label="Exam Max Score:"
                            v-model="form.exam_maxscore" 
                            dense
                            outlined
                            ></v-select>
                            </v-col>
                        </v-row>
                        <v-subheader class="caption red--text" v-if="school.plan =='Free'">Disabled checkboxes are not available to schools using the FREE Version</v-subheader>
                        <v-row>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.motto" @change="emitForm" label="Show School Motto"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.address" @change="emitForm" label="Show School Contact Info"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.watermark" @change="emitForm" label="Show School Logo Watermark" :disabled="school.plan =='Free'"></v-checkbox></v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.regnum" @change="emitForm" label="Show Student Reg. Number"></v-checkbox>
                            </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.age" @change="emitForm" label="Show Student Age"></v-checkbox>
                            </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.photo" @change="emitForm" label="Show Student Photo" :disabled="school.plan =='Free'"></v-checkbox>
                            </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.attendance" @change="emitForm" label="Show Student Attendance"></v-checkbox>
                            </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.gender" @change="emitForm" label="Show Student Gender"></v-checkbox>
                            </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.csize" @change="emitForm" label="Show Class Size"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.sascore" @change="emitForm" label="Show Subject Average Score"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.shlscores" @change="emitForm" label="Show Subject Highest and Lowest Scores" :disabled="school.plan =='Free'"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.sposition" @change="emitForm" label="Show Subject Position"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.sgrading" @change="emitForm" label="Show Subject Grading"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.sremarks" @change="emitForm" label="Show Subject Remarks"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3"><v-checkbox v-model="rsettings.ptss" @change="emitForm" label="Show Possible Total Score for Subjects" :disabled="school.plan =='Free'"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.cummulative" @change="emitForm" label="Show Three Terms Cummulative Scores" :disabled="school.plan =='Free'"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.cposition" @change="emitForm" label="Show Student Class Position"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.ntrd" @change="emitForm" label="Show Next Term Resumption Date"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.ktg" @change="emitForm" label="Show Keys to Grading"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.cteacher" @change="emitForm" label="Show Class Teacher Name" :disabled="school.plan =='Free'"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.shremarks" @change="emitForm" label="Show Remarks"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.psymotor" @change="emitForm" label="Show Psycho-motors"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <v-checkbox v-model="rsettings.pinfo" @change="emitForm" label="Show Info to Parents" :disabled="school.plan =='Free'"></v-checkbox>
                        </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios';
export default {
    name: 'SettingsForm',
    props:[
        'authToken', 
        'accessToken', 
        'school',
        'terms',
        'classes',
        'templates',
    ],
    data () {
        return {
            rsettings:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')).settings : {},
            showMore: false,
            form: {
                parameter_id: this.terms.length > 0 ? this.terms[0].parameter_id : null,
                ca1_maxscore: this.school.school_id == '5e1e7978-e58e-4f95-8269-3069cbf5f402' ? '10' : '20',
                ca2_maxscore: this.school.school_id == '5e1e7978-e58e-4f95-8269-3069cbf5f402' ? '10' : '20',
                ca3_maxscore: this.school.school_id == '5e1e7978-e58e-4f95-8269-3069cbf5f402' ? '10' : '20',
                ca4_maxscore: this.school.school_id == '5e1e7978-e58e-4f95-8269-3069cbf5f402' ? '10' : '20',
                exam_maxscore: this.school.school_id == '5e1e7978-e58e-4f95-8269-3069cbf5f402' ? '70' : '60',
            },
            scoreGroupMax: ['10', '15', '20', '30', '40', '50', '60', '70', '80'],
            students: [],
            subjects: [],
            cacount: this.school.kinds_of_ca,
        }
    },
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods:{
        emitForm (){
            if (this.form.parameter_id && this.form.clasx_id) { 
                this.$emit('settings', [{settings:this.rsettings, form:this.form}])
            }
        },
        updateCaCount(){
            this.school.kinds_of_ca = this.cacount
            localStorage.school =  JSON.stringify(this.school)
        },
        fetchStudents (){
            if (this.form.parameter_id && this.form.clasx_id) { 
                let loader = this.$loading.show()
                axios.get(process.env.VUE_APP_BASEURL+'/students?school_id='+this.school.school_id+'&class_id='+this.form.clasx_id+'&parameter_id='+this.form.parameter_id,
                {
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken,
                        'access_token': this.accessToken,
                    }
                })
                .then((response) => {
                    this.students =  response.data.data
                    this.form.scs_id = null
                    this.$emit('students', this.students) 
                    if (this.students.length == 0) {
                        this.$toast.info('No Students in selected class. ADD or PROMOTE students to class!', {
                                timeout: 5000
                            }); 
                        this.$router.push('students')
                    }  
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.emitForm()
                    loader.hide()
                });
            }    
        },
    }
}
</script>

<style scoped>
</style>