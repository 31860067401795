<template>
  <div v-if="school">
      <small v-if="rsettings[0].settings.address"><em>{{school.address}}</em></small><br>
      <small v-if="rsettings[0].settings.address">
          TEL: <strong>{{school.phone}}</strong>, EMAIL: <strong>{{school.email}}</strong>
        </small>
  </div>
</template>

<script>
export default {
    name: 'SchoolAddress',
    props:[
        'school',
        'rsettings',
    ],
}
</script>