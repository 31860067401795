<template>
  <v-row class="print-area">
      <v-col cols="12">
        <v-card>
            <v-toolbar
            dark
            color="#A03240"
            class="noprint"
            dense
            >
            <v-btn
                icon
                dark
                @click="reloadPage"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>STUDENT RESULT SHEETS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn
                fab
                    color="black"
                    class="noprint"
                    @click="e1 = 1"
                    >
                    <v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
                <v-btn
                    fab
                    color="green"
                    href="javascript:window.print()"
                    class="noprint"
                    >
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </v-toolbar-items>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header class="noprint">
                <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                >
                    Result Settings
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                >
                Print Sheets
                </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card
                    class="mb-12"
                    color="#fff"
                    >                
                        <!-- Settings Form -->
                        <settings-form :classes="classes" :terms="terms" :authToken="authToken" :accessToken="accessToken" :school="school" :templates="templates" @students="studentRecords" @settings="emittedSettings"/>

                        <info-loader v-if="fetching">Kindly be patient! The amount of wait time depends on the total number of student records being fetched &amp; your network strength. </info-loader>
                    </v-card>

                    <v-btn
                    :disabled="!fetchF"
                    color="primary"
                    @click="step2"
                    class="noprint"
                    >
                    Continue
                    </v-btn>

                    <v-btn text @click="reloadPage" class="noprint">
                    Close
                    </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card 
                    v-if="records"
                    class="mb-12"
                    color="#fff"
                    >
                        
                    <!-- Templates -->
                    <custom-template-changer :records="records" :school="school" :rsettings="settings" :terms="terms" :gradings="gradings"/>
                    </v-card>

                    <v-btn
                    color="primary"
                    href="javascript:window.print()"
                    class="noprint"
                    >
                    <v-icon>mdi-printer</v-icon>
                    Print
                    </v-btn>

                    <v-btn text @click="e1 = 1" color="red" class="noprint">
                        <v-icon>mdi-chevron-double-left</v-icon>
                        Back
                    </v-btn>
                    <v-btn text @click="reloadPage" class="noprint">
                    Close
                    </v-btn>
                </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
      </v-col>
  </v-row>
</template>

<script>
import SettingsForm from '../sheets/SettingsForm.vue'
import axios from 'axios';
import CustomTemplateChanger from './Templates/CustomTemplateChanger.vue';
import InfoLoader from '../InfoLoader.vue';
export default {
    components: { SettingsForm, CustomTemplateChanger, InfoLoader },
    name: 'StudentResultSheetModal',
    props:[
        'terms', 
        'classes',  
        'gradings', 
        'school',
        'authToken',
        'accessToken'
    ],
    data () {
        return {
            e1: 1,
            fetching:false,
            dialog: true,
            records: null,
            settings: {},
            students:null,
            fetchF:false,
            reRenderComponent:true,
            templates:[
                {id: 1, schid:'all', name:'Default'},
                {id: 2, schid:'al-a228061785287041503868', name:'Al-meen'},
                {id: 3, schid:'arie604962976004025310065', name:'Ariela Basic'},
                {id: 4, schid:'a126c97a-238b-4328-9ba7-740e241171e9', name:'Ariela High'},
                {id: 5, schid:'1b0e1eaf-8f61-4685-a837-e42b0da4dce4', name:'Batool Nur/Pry'},
                {id: 6, schid:'b19b267a-3410-4efc-af8d-9dc96c3dca3b', name:'Batool Secondary'},
            ],
        }
    },
    computed:{
        //
    },
    methods: {
        emittedSettings (value){
            this.settings = value
            this.fetchF = true
        },
        studentRecords (value){
            this.students = value
        },
        reloadPage (){
            window.location.reload();
        },
        saveFetchSettings (){
            axios.post(process.env.VUE_APP_BASEURL+'/settings?school_id='+this.school.school_id,this.settings[0].settings,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                localStorage.school =  JSON.stringify(response.data.data)           
            })
            .catch((error) => {
                console.log(error.response.status)
            })
            .finally(() => {
            });
        },
        fetchResults () {
            this.fetching = true 
            let cumm = 0
            if (this.settings[0].settings.cummulative) {
                   cumm = 1        
            } 
            axios.get(process.env.VUE_APP_BASEURL+'/broadsheets?school_id='+this.school.school_id+'&clasx_id='+this.settings[0].form.clasx_id+'&parameter_id='+this.settings[0].form.parameter_id+'&group_by=students&show_cummulative='+cumm,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken,
                    'access_token': this.accessToken,
                }
            })
            .then((response) => {
                this.records =  response.data.data                
                this.e1 = 2       
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                this.fetching = false,
                this.saveFetchSettings()
            });
        },
        step2 (){
            this.reRenderComponent = false;
            this.$nextTick(() => {
            // Add the component back in
            this.reRenderComponent = true;
            });
            this.fetchResults()
        }
    }
  }
</script>

<style>
.bborder {
    border-bottom: 1px solid #444;
}
.hborder {
    border: 2px solid #000;
}

.allborder {
    border: 1px solid #444;
}

.lbborder {
    border-left: 1px solid #444;
    border-bottom: 1px solid #444;
}
.rbborder {
    border-right: 1px solid #444;
    border-bottom: 1px solid #444;
}
.rborder {
    border-right: 1px solid #444;
}
.lborder {
    border-left: 1px solid #444;
}
.tbborder {
    border-bottom: 1px solid #444;
    border-top: 1px solid #444;
}
.tborder {
    border-top: 1px solid #444;
}
h2{
    font-family: Lato,Arial,sans-serif !important;
}
</style>