<template>
    <v-row>
        <v-col cols="12" class="d-flex d-sm-none d-print-none">
        <div>
            Click the Print button to see a better preview
        </div>
        </v-col>
        <v-col cols="12" class="print-zone"> 
            <v-col cols="12" class="text-center">
                <school-logo :school="school"/>
                <school-name :school="school"/>
                <small>
                    <span v-for="clasx in classes.filter((item)=>{return item.class_id == settings.clasx_id})" :key="clasx.class_id"> {{clasx.class}}</span> /
                    <span v-for="term in terms.filter((item)=>{return item.parameter_id == settings.parameter_id})" :key="term.parameter_id"> {{term.term}}Term - {{term.session.session}} Session</span>
                </small>
                <h3>Broadsheet by Students</h3>
            </v-col>
            <div class="hborder">
               <v-row class="px-4 py-4">
                    <v-col cols="12" style="background:#f5f5f5" class="vtextdiv">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <thead>
                                <tr>   
                                    <th class="text-left">Final Pos.#</th>
                                    <th class="text-left">FullName #</th>
                                    <th class="text-left">Total</th>
                                    <th class="text-left">Average</th>
                                    <th class="text-left">Grade</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="record in records"
                                :key="record.scs_id"
                                >
                                    <td>
                                        <v-chip color="#FFF9C4" v-if=" record.computations.student_position == '1st'">1st</v-chip>
                                        <v-chip color="#F5F5F5" v-else-if=" record.computations.student_position == '2nd'">2nd</v-chip>
                                        <v-chip color="#D7CCC8" v-else-if=" record.computations.student_position == '3rd'">3rd</v-chip>
                                        <v-chip color="white" v-else>{{ record.computations.student_position }}</v-chip>
                                    </td>
                                    <td>{{ record.student.fullname  }}</td>
                                    <td>{{ record.computations.total_score  }}</td>
                                    <td ><v-chip color="#FFFF8D">{{ record.computations.average_score  }}</v-chip></td>
                                    <td><v-chip text color="#B2EBF2">{{ record.computations.overall_grade }}</v-chip></td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                 <v-row>
                    <v-col cols="12" class="text-center">
                        <small v-if="school.plan == 'Free'">Printed from MYSCH.NG</small>
                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import SchoolLogo from './Parts/SchoolLogo.vue'
import SchoolName from './Parts/SchoolName.vue'
export default {
  components: { SchoolLogo, SchoolName },
    name: 'BroadSheetByStudents',
    props:[
        'records', 
        'classes', 
        'terms', 
        'school',
        'settings',
        'subjects',
    ],
    data () {
        return {
           //
        }
    },
    methods:{
    }
}
</script>

<style scoped>
@media print{
    .print-zone{
        page-break-after: always;
        font-weight: 500;
    }
}
@media only screen and (max-width: 450px) {
    .print-zone{
        zoom: 0.5;
        -moz-transform: scale(0.5);
    }
}
@media only screen and (min-width: 451px) and (max-width: 850px) {
    .print-zone{
        zoom: 0.7;
        -moz-transform: scale(0.7);
    }
}
</style>
