<template>
    <div v-if="record && terms && rsettings">
        <div v-if="rsettings[0].settings.attendance">
            <span v-for="(term,i) in terms.filter((item)=>{return item.parameter_id == rsettings[0].form.parameter_id})" :key="i">
                Total Days In Term: <strong>{{term.schooldays}}</strong><br>
            </span>
            <span v-if="record.remarks">Total Days Present: <strong>{{record.remarks.days_present}}</strong><br></span>
            <span v-if="record.remarks">Total Days Absent: <strong>{{record.remarks.days_absent}}</strong></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Attendance',
    props:[
        'terms',
        'rsettings',
        'record',
    ],
}
</script>