<template>
    <v-row v-if="!rsettings[0].settings.cummulative">
        <v-col cols="12">
            <table class="table mnr" style="z-index:2" v-if="record">
                <thead>
                    <tr class="headrow">
                        <th :class="'text-left '+styler">SUBJECTS</th>
                        <score-header :class="styler" :header="'1st CA ('+rsettings[0].form.ca1_maxscore+')'"/>
                        <score-header :class="styler" v-if="school.kinds_of_ca > 1" :header="'2nd CA ('+rsettings[0].form.ca2_maxscore+')'"/>
                        <score-header :class="styler" v-if="school.kinds_of_ca > 2" :header="'3rd CA ('+rsettings[0].form.ca3_maxscore+')'"/>
                        <score-header :class="styler" v-if="school.kinds_of_ca > 3" :header="'4th CA ('+rsettings[0].form.ca4_maxscore+')'"/>
                        <score-header :class="styler" :header="'EXAM ('+rsettings[0].form.exam_maxscore+')'"/>
                        <score-header :class="styler" :header="'TOTAL (100)'"/>
                        <score-header :class="styler" v-if="rsettings[0].settings.sgrading" :header="'GRADE'"/>
                        <score-header :class="styler" v-if="rsettings[0].settings.sremarks" :header="'REMARK'"/>
                        <score-header :class="styler" v-if="rsettings[0].settings.sposition" :header="'SUBJECT POSITION'"/>
                        <score-header :class="styler" v-if="rsettings[0].settings.shlscores" :header="'SUBJECT HIGHEST'"/>
                        <score-header :class="styler" v-if="rsettings[0].settings.shlscores" :header="'SUBJECT LOWEST'"/>
                        <score-header :class="styler" v-if="rsettings[0].settings.sascore" :header="'SUBJECT AVERAGE'"/>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="score in record.scores"
                    :key="score.result_id"
                    >
                    <score-data :class="'text-capitalize '" :data="score.subject.subject"/>
                    <score-data :class="'text-center '" :data="score.this_term_scores.ca1"/>
                    <score-data v-if="school.kinds_of_ca > 1" :class="'text-center '" :data="score.this_term_scores.ca2"/>
                    <score-data v-if="school.kinds_of_ca > 2" :class="'text-center '" :data="score.this_term_scores.ca3"/>
                    <score-data v-if="school.kinds_of_ca > 3" :class="'text-center '" :data="score.this_term_scores.ca4"/>
                    <score-data :class="'text-center '" :data="score.this_term_scores.exam"/>
                    <score-data :class="'text-center font-weight-bold'" :data="score.this_term_scores.total"/>
                    <score-data v-if="rsettings[0].settings.sgrading" class="text-center font-weight-bold" :data="score.this_term_scores.grade"/>
                    <score-data v-if="rsettings[0].settings.sremarks" class="text-center font-weight-bold" :data="score.this_term_scores.remarks"/>
                    <score-data v-if="rsettings[0].settings.sposition" class="text-center font-weight-bold" :data="score.this_term_scores.computed_subject_position"/>
                    <score-data v-if="rsettings[0].settings.shlscores" class="text-center" :data="score.this_term_scores.subject_highest"/>
                    <score-data v-if="rsettings[0].settings.shlscores" class="text-center" :data="score.this_term_scores.subject_lowest"/>
                    <score-data v-if="rsettings[0].settings.sascore" class="text-center" :data="score.this_term_scores.subject_average"/>
                    </tr>
                </tbody>
            </table>
        </v-col>
    </v-row>
</template>

<script>
import ScoreData from './ScoreData.vue'
import ScoreHeader from './ScoreHeader.vue'
export default {
  components: { ScoreData, ScoreHeader },
    name: 'OneTermSubjectScores',
    props:[
        'record',
        'rsettings',
        'school',
        'styler'
    ],
}
</script>

<style>
.mnr{
    border-collapse: collapse;
    width: 100%;
}
.mnr tr > td, .mnr tr > th{
    border: 2px solid #333 !important; 
    padding: 0 4px 0 4px;
}
.mnr tbody tr td{
    font-size: 12px;
}
.headrow{height: 150px;}
.headrow th.vtext{height:100px; vertical-align: bottom; padding-left: 0;}
.headrow th.vtext > div{
    transform: rotate(270deg);
    transform-origin: 0% 0%;
    width: 1px;
}
tr th.vtext span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 130px;
}
</style>