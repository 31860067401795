<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="450"
    >
      <v-card dark loading>
        <v-card-title class="text-h5">
          Fetching Content...
        </v-card-title>
        <v-card-text>
            <slot></slot>
            &nbsp;
            <v-progress-circular
            indeterminate
            color="blue"
            ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
        name: 'InfoLoader',
        data () {
        return {
            dialog:true
        }
    },
  }
</script>