<template>
    <v-row>
        <v-col cols="12">
            <v-form>
                <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                    <v-select
                    :items="terms"
                    item-text="term_session"
                    item-value="parameter_id"
                    @change="emitForm"
                    label="Select Term:"
                    v-model="form.parameter_id"
                    outlined
                    dense
                    :rules="[v => !!v || 'Term need to be selected!']"
                    required
                    ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                    <v-select
                    :items="classes"
                    item-text="class"
                    item-value="class_id"
                    label="Select Class:"
                    v-model="form.clasx_id"
                    outlined
                    dense
                    :rules="[v => !!v || 'Class need to be selected!']"
                    required
                    @change="fetchSubjects()"
                    ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                    <v-select
                    :disabled="school.plan =='Free'"
                    :items="broadtype"
                    item-text="type"
                    item-value="id"
                    @change="emitForm" label="Group By Students / By Subjects"
                    v-model="form.group_by"
                    outlined
                    dense
                    ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                        <v-subheader class="caption red--text" v-if="school.plan =='Free'">Disabled select boxes are not available to schools using the FREE Version</v-subheader>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios';
export default {
    name: 'BroadsheetSelectForm',
    props:[
        'school',
        'authToken',
        'terms',
        'classes',
    ],
    data () {
        return {
            form: {
                group_by: 'subjects'
            },
            broadtype: [
                {id: 'subjects', type: 'By Subjects'},
                {id: 'students', type: 'By Students'}
            ]
        }
    },
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods:{
        emitForm (){
            if (this.form.parameter_id && this.form.clasx_id) { 
                this.$emit('settings', this.form)
            }
        },
        fetchSubjects (){
            if (this.form.parameter_id && this.form.clasx_id) { 
                let loader = this.$loading.show()
                axios.get(process.env.VUE_APP_BASEURL+'/subjects?school_id='+this.school.school_id+'&class_id='+this.form.clasx_id+'&parameter_id='+this.form.parameter_id,
                {
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
                })
                .then((response) => {
                    this.subjects =  response.data.data
                    this.$emit('subjects', this.subjects) 
                    if (this.subjects.length == 0) {
                        this.$toast.info('No Subjects registered to class. ADD or Update subjects!', {
                            timeout: 5000
                        }); 
                        this.$router.push('subjects')
                    }  
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.emitForm()
                    loader.hide()
                });
            }    
        },
    }
}
</script>

<style scoped>
</style>