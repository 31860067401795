<template>
    <div v-if="record">
        <span>Class: <strong>{{record.class.class}}</strong></span>
    </div>
</template>

<script>
export default {
    name: 'StudentClass',
    props:[
        'record',
    ],
}
</script>