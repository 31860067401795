<template>
    <v-row v-if="rsettings && gradings">
        <v-col cols="12" v-if="rsettings[0].settings.ktg">
            <h5 :class="styler">GRADING SYSTEM</h5>
            <table class="table mnr">
            <tbody>
                <tr v-for="(grading,i) in gradings" :key="i">
                <td>{{grading.grade}}</td>
                <td>{{grading.lower_limit}}-{{grading.upper_limit}}</td>
                </tr>
            </tbody>
            </table>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'GradingSystem',
    props:[
        'gradings',
        'rsettings',
        'styler'
    ],
}
</script>
<style scoped>
.col-12{
    padding-right: 5px !important;
    padding-left: 5px !important;
}
table{border-spacing: 0 !important;}
.mnr tbody tr td{
    font-size: 11px !important;
    border: 1px solid #444 !important;
}
</style>